<template>
  <div id='quick-ticket-selector' :class="{ 'visible': isVisible }" v-on-clickaway="handleClickAway">
    <div class="module-wrapper">
      <div class="image-col" :class="{ 'ticket-selector-active': this.activeTabIndex === 1 }">
        <div class="wrapper">
          <transition
            mode="out-in"
            enter-active-class="animate__animated animate__fadeIn animate__faster"
            leave-active-class="animate__animated animate__fadeOut animate__faster"
          >
            <img v-if="facilityImgUrl" class="thumbnail" :src="facilityImgUrl" >
          </transition>
          <span class="facility-name" :style="`color: ${facilityColor}`" v-html="facilityName" />
          <span class="postfix">{{ $t('general.quickTicketSelector.imageCard.subtitle') }}</span>

          <div class="facility-info" :class="{'no-tooltip': !facilityDescriptionKey }">
            <span v-html="facilityDescriptionKey" />
          </div>
        </div>
      </div>
      <div class="selectors-col">
        <base-tabs-module
          :class="{ 'ticket-selector-active': this.activeTabIndex === 1 }"
          :enumKey="enumKey"
          :activeTabIndex="activeTabIndex"
          :tabs="tabs"
          @tabClicked="handleTabChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import BaseTabsModule from '@/components/shared/modules/tabs/BaseTabsModule';
import facilities from '@/components/shared/mixins/facilities';

const QTSDatePicker = () => import('@/components/shared/modules/pickers/QTSDatePicker');
const QTSTickets = () => import('@/components/shared/modules/tickets/QTSTickets');

export default {
  name: 'QuickTicketSelector',
  mixins: [clickaway, facilities],
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    enumKey: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseTabsModule,
  },
  data: (that = this) => ({
    openCount: 0,
    activeTabIndex: 0,
    tabs: [
      {
        tabTitle: 'general.quickTicketSelector.tabs.date',
        isCompleted: false,
        isDisabled: false,
        component: {
          bind: {
            is: QTSDatePicker,
          },
          on: {
            buttonClicked: that.switchTicketTab,
          },
        },
      },
      {
        tabTitle: 'general.quickTicketSelector.tabs.ticket',
        isCompleted: false,
        isDisabled: false,
        component: {
          bind: {
            is: QTSTickets,
            groupId: that.$FACILITIES.ID_TO_KEY[that.enumKey],
          },
        },
      },
    ],
  }),
  created() {},
  computed: {
    ...mapGetters({
      getLocalCikkcsoports: 'cikkcsoports/getLocalCikkcsoports',
    }),
    facilityImgUrl() {
      if (!this.isVisible) {
        return undefined;
      }

      return this.$FACILITIES.THUMBNAIL[this.enumKey];
    },
    facilityName() {
      return this.$t(this.$FACILITIES.NAME_KEY[this.enumKey]);
    },
    facilityColor() {
      return this.$FACILITIES.COLOR[this.enumKey];
    },
    facilityDescriptionKey() {
      // eslint-disable-next-line eqeqeq
      const cikkcsoport = this.getLocalCikkcsoports.find((item) => item.cikkcsop_id == this.$FACILITIES.ID_TO_KEY[this.enumKey]);

      if (cikkcsoport && cikkcsoport.facilityDescription) {
        if (cikkcsoport.facilityDescription.description && cikkcsoport.facilityDescription.externalLink) {
          return `${cikkcsoport.facilityDescription.description} <br><a target='_blank' href="${cikkcsoport.facilityDescription.externalLink}">${cikkcsoport.facilityDescription.externalLink}</a>`;
        }
        return cikkcsoport.facilityDescription.description;
      }
      return undefined;
    },
  },
  watch: {
    isVisible(val) {
      if (val) {
        this.tabs[1].component.bind.groupId = this.$FACILITIES.ID_TO_KEY[this.enumKey];
      } else {
        this.activeTabIndex = 0;
      }
    },
  },
  methods: {
    switchTicketTab() {
      this.tabs[0].isCompleted = true;
      this.activeTabIndex = 1;
    },
    handleTabChange(index) {
      if (!this.tabs[index].isDisabled) {
        this.activeTabIndex = index;
      }
    },
    handleClickAway() {
      if (this.isVisible) {
        this.openCount += 1;
      }

      if (this.isVisible && this.openCount > 1) {
        this.$emit('clickedAway');
        this.$eventBus.quickTicketSelectorClosed();
        this.openCount = 0;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
$imageColWidth: 325px;
$thumbnailHeight: 260px;

@keyframes fadeInShow {
  0% {
    display: block;
  }
  100% {
    opacity: 1;
  }
}

#quick-ticket-selector {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0) translate(-50%, -50%);
  margin-left: 70px;
  z-index: z('above-page-body');
  transition: $transitionBase;
  transform-origin: top left;
  opacity: 0;

  @media screen and (max-width: $breakpointDownXl) {
    top: 120px;
    left: 50%;
    transform: scale(0) translate(0, -50%);
    margin-left: 120px;
  }

  @media screen and (max-width: $breakpointDownMd) {
    margin-left: 90px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    margin-left: 0;
    top: 0;
    width: 85%;
  }

  &.visible {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;

    @media screen and (max-width: $breakpointDownXl) {
      transform: scale(1) translate(-50%, 0);
    }
  }
}

.module-wrapper {
  @media screen and (max-width: $breakpointDownSm) {
    display: flex;
    flex-wrap: wrap;
  }
}

.image-col {
  width: $imageColWidth;
  position: absolute;
  top: 50%;
  left: -$imageColWidth + 15px;
  transform: translateY(-50%);
  margin-top: 40px;
  transition: all 0.7s ease-in-out;
  z-index: 1;

  @media screen and (max-width: $breakpointDownMd) {
    width: 200px;
    left: -180px;
  }

  @media screen and (max-width: $breakpointDownSm) {
    order: 2;
    position: relative;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    margin-top: 0;
  }

  &.ticket-selector-active {
    margin-top: 20px;

    @media screen and (max-width: $breakpointDownSm) {
      margin-top: 0;
    }
  }

  .thumbnail {
    height: $thumbnailHeight;
    width: 100%;
    object-fit: cover;
    border-radius: $globalBorderRadius $globalBorderRadius 0 0;

    @media screen and (max-width: $breakpointDownMd) {
      height: calc(#{$thumbnailHeight} - 60px);
    }

    @media screen and (max-width: $breakpointDownSm) {
      border-radius: 0;
    }
  }

  .wrapper {
    border-radius: $globalBorderRadius;
    background-color: $white;
    box-shadow: $lightDropdownShadow;
    text-align: center;
    position: relative;

    @media screen and (max-width: $breakpointDownSm) {
      border-radius: 0 0 $globalBorderRadius $globalBorderRadius;
    }
  }

  .facility-info {
    font-weight: 500;
    font-size: 11px;
    line-height: 1.4;
    padding: 0 10px 15px;

    &::v-deep {
      a {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.no-tooltip:hover {
      .icon {
        opacity: 0.4;
      }
    }

    &:hover {
      .info-tooltip {
        display: block;
        animation: fadeInShow 0.4s forwards;
      }

      .icon {
        opacity: 0.8;
      }
    }

    .icon {
      opacity: 0.4;
      cursor: pointer;
      display: block;
      transition: $transitionBase;
    }

    .info-tooltip {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      background-color: $white;
      color: $globalFontColor;
      min-width: 350px;
      max-width: 400px;
      border-radius: $globalBorderRadius;
      font-size: 12px;
      line-height: 1.4;
      padding: 10px 30px;
      text-align: center;
      height: auto;
      display: inline-block;
      box-shadow: $cartItemRowShadow;
      font-weight: 500;
      display: none;
      opacity: 0;

      @media screen and (max-width: $breakpointDownSm) {
        left: auto;
        right: -15px;
        transform: none;
      }

      @media screen and (max-width: $breakpointDownXs) {
        min-width: 280px;
      }

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #fff;
        position: absolute;
        bottom: -10px;
        left: calc(50% - 10px);
        transform: translateY(-50%);

        @media screen and (max-width: $breakpointDownSm) {
          left: auto;
          right: 13px;
          transform: none;
          bottom: -6px;
        }
      }
    }
  }

  .facility-name {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 11px 0 0;
    line-height: 1;
    display: block;

    &::v-deep {
      small {
        font-weight: 700;
        font-size: 12px;
        display: block;
        line-height: 1;
      }
    }
  }

  .postfix {
    font-size: 10px;
    font-weight: 700;
    display: block;
    line-height: 1;
    padding: 0 0 12px;
  }
}

.selectors-col {
  @media screen and (max-width: $breakpointDownSm) {
    width: 100%;
  }
}

.base-tabs-module {
  width: 450px;
  transition: all 0.7s ease-in-out;

  @media screen and (max-width: $breakpointDownSm) {
    width: 100%;
  }

  &::v-deep {
    .tab-contents {
      box-shadow: $lightDropdownShadow;
      padding: 35px 30px 20px 40px;
      display: block;

      @media screen and (max-width: $breakpointDownSm) {
        padding: 25px 25px 35px;
      }
    }
  }

  &.ticket-selector-active {
    width: 600px;

    @media screen and (max-width: $breakpointDownMd) {
      width: 500px;
    }

    @media screen and (max-width: $breakpointDownSm) {
      width: 100%;
    }
  }
}
</style>
