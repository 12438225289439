<template>
  <div class="base-tabs-module" :style="`--itemsNumber: ${tabs.length}`">
    <div class="tab-headers">
      <div
        v-for="(tab, i) in tabs" :key="i"
        class="tab" :class="[{ 'active': activeTabIndex === i }, { 'completed': tab.isCompleted }, { 'disabled': tab.isDisabled }]"
        @click="handleTabClick(i, tab.isDisabled)"
      >
        <span class="indicator">
          <template v-if="!tab.isCompleted">{{ i+1 }}</template>
          <template v-else><icon-check /></template>
        </span>
        <span class="title">{{ $t(tab.tabTitle) }}</span>
      </div>
    </div>
    <div class="tab-contents">
      <transition mode="out-in" name="fadeDown" enter-active-class="fadeDown" leave-active-class="fadeUp">
        <component
          :key="activeTabIndex"
          v-bind="currentPageComponent.bind"
          v-bind:is="currentPageComponent.bind.is"
          v-on="currentPageComponent.on"
          :enumKey="enumKey"
        />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTabsModule',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    activeTabIndex: {
      type: Number,
      required: true,
    },
    enumKey: {
      type: String,
      required: false,
    },
  },
  components: {
    'icon-check': () => import('@/components/shared/elements/icons/IconCheck'),
  },
  data: () => ({
  }),
  created() {},
  computed: {
    currentPageComponent() {
      return this.tabs[this.activeTabIndex].component;
    },
  },
  methods: {
    handleTabClick(tabIndex, disabledStatus) {
      if (!disabledStatus) {
        this.$emit('tabClicked', tabIndex);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@keyframes fade-up {
  0% {
    max-height: 1000px;
    opacity: 1;
  }
  100% {
    max-height: 300px;
    opacity: 0;
    overflow: hidden;
  }
}

@keyframes fade-down {
  0% {
    max-height: 300px;
    opacity: 0;
    overflow: hidden;
  }
  100% {
    max-height: 1000px;
    opacity: 1;
  }
}

.fadeDown {
  animation: fade-down 0.7s;
}

.fadeUp {
  animation: fade-up 0.4s;
}

$tabHeader: 38px;
$indicatorSize: 22px;

.tab-headers {
  display: grid;
  grid-template-columns: repeat(var(--itemsNumber), 1fr);
  column-gap: 18px;
  padding: 0 17px;

  @media screen and (max-width: $breakpointDownSm) {
    padding: 0 8px;
    column-gap: 8px;
  }

  .tab {
    border-radius: $globalBorderRadius $globalBorderRadius 0 0;
    background-color: $tabBackground;
    color: $white;
    font-size: 12px;
    font-weight: 700;
    height: $tabHeader;
    line-height: $tabHeader;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    transition: $transitionBase;

    &.active,
    &.completed {
      background-color: $primaryOrangeLight;

      .indicator {
        color: $primaryOrangeLight;
      }
    }

    &.disabled {
      cursor: default;
    }

    .indicator {
      width: $indicatorSize;
      height: $indicatorSize;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;
      color: $tabBackground;
      margin: 0 10px 0 0;
      position: relative;
      transition: $transitionBase;

      @media screen and (max-width: $breakpointDownXs) {
        margin: 0;
      }

      .icon-check {
        position: absolute;
        top: -2px;
        right: -3px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .title {
      @media screen and (max-width: $breakpointDownXs) {
        display: none;
      }
    }
  }
}

.tab-contents {
  border-radius: $globalBorderRadius;
  background-color: $white;
  width: 100%;
  min-height: 300px;
  height: 100%;
  padding: 33px 80px 30px;
  transition: height 0.7s ease-in-out;

  @media screen and (max-width: $breakpointDownLg) {
    padding-left: 45px;
    padding-right: 45px;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    padding-left: 25px;
    padding-right: 25px;
  }
}
</style>
