export default {
  methods: {
    getFacilityDescriptionKey(cikkcsoportId) {
      switch (cikkcsoportId) {
        case this.$FACILITIES.ID_TO_KEY.BEACH:
          return this.$FACILITIES.DESCRIPTION_KEYS.BEACH;
        case this.$FACILITIES.ID_TO_KEY.THERMAL:
          return this.$FACILITIES.DESCRIPTION_KEYS.THERMAL;
        case this.$FACILITIES.ID_TO_KEY.AQUA_PALACE:
          return this.$FACILITIES.DESCRIPTION_KEYS.AQUA_PALACE;
        case this.$FACILITIES.ID_TO_KEY.AQUA_PARK:
          return this.$FACILITIES.DESCRIPTION_KEYS.AQUA_PARK;
        case this.$FACILITIES.ID_TO_KEY.PREMIUM:
          return this.$FACILITIES.DESCRIPTION_KEYS.PREMIUM;
        case this.$FACILITIES.ID_TO_KEY.POOL:
          return this.$FACILITIES.DESCRIPTION_KEYS.POOL;
        default:
          return undefined;
      }
    },
    getFacilityNameById(cikkcsoportId) {
      switch (cikkcsoportId) {
        case this.$FACILITIES.ID_TO_KEY.BEACH:
          return this.$FACILITIES.NAME_KEY.BEACH;
        case this.$FACILITIES.ID_TO_KEY.THERMAL:
          return this.$FACILITIES.NAME_KEY.THERMAL;
        case this.$FACILITIES.ID_TO_KEY.AQUA_PALACE:
          return this.$FACILITIES.NAME_KEY.AQUA_PALACE;
        case this.$FACILITIES.ID_TO_KEY.AQUA_PARK:
          return this.$FACILITIES.NAME_KEY.AQUA_PARK;
        case this.$FACILITIES.ID_TO_KEY.PREMIUM:
          return this.$FACILITIES.NAME_KEY.PREMIUM;
        case this.$FACILITIES.ID_TO_KEY.POOL:
          return this.$FACILITIES.NAME_KEY.POOL;
        default:
          return undefined;
      }
    },
  },
};
